<template>
  <div class="main-contents info_mf code pit">
  <div class="tit">적립 포인트 코드 등록<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="movePage('back')"/></div>
  <div class="sub-tit"><p>적립 포인트 코드 등록</p></div>
  <div class="enter_inform">
    <div class="form">
      <div class="label">적립 포인트 코드</div>
      <div class="list">
        <InputComp v-model="pointcode.pointSaveCd" title="적립 포인트 코드" :rules="{'required':true, 'maxLength':6, 'minLength':6}" v-bind:disabled="pointSaveCd != ''"/>
      </div>
    </div>
    <div class="form">
      <div class="label">적립 포인트 항목명</div>
      <div class="list">
        <InputComp v-model="pointcode.pointSaveCdNm" title="적립 포인트 항목명" maxByte="100" :rules="{'required':true}"/>
      </div>
    </div>
    <div class="form">
      <div class="label">적립 포인트 항목설명</div>
      <div class="list">
        <InputComp v-model="pointcode.pointSaveCdCont" maxByte="500"/>
      </div>
    </div>
    <div class="form">
      <div class="label">적립 포인트 대상구분</div>
      <div class="list">
        <SelectComp v-model="pointcode.pointSaveTargetDivCd" cdId="PRO130" title="적립 포인트 대상구분" :rules="{'required':true}"/>
      </div>
    </div>
    <div class="form">
      <div class="label">사용여부</div>
      <div class="list">
        <SelectComp v-model="pointcode.useYn" list="Y:예,N:아니오" title="사용여부" :rules="{'required':true}"/>
      </div>
    </div>
    </div>
    <div class="select_btn">
      <div class="btn_01" @click="movePage('back')">취소</div>
      <div class="btn_02" v-if="pointSaveCd == ''" @click="insertPointCode()">등록</div> 
      <div class="btn_02" style="background-color: #FF681D;" v-else @click="updatePointCode()">수정</div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      pointcode : {
        pointSaveCd : '',
        pointSaveCdNm : '',
        pointSaveCdCont : '',
        pointSaveTargetDivCd : '',
        useYn : ''
      },

      pointSaveCd : this.$route.params.pointSaveCd || ''
    }
  },

  beforeMount(){
    if(this.pointSaveCd != '') this.getPointCodeInfo();
  },

  methods : {
    getPointCodeInfo(){
      // var param = {};
      // param.pointSaveCd = this.pointSaveCd;

      this.$.httpPost('/api/main/adm/point/getPointCodeInfo', {pointSaveCd : this.pointSaveCd})
        .then(res=>{
          // console.log('fwefwefew', this.pointInfo);
          this.pointcode = res.data.pointcode || {};
        }).catch(this.$.httpErrorCommon);
    },

    insertPointCode(){
      this.$.popup('/adm/man/MAN934P01')
        .then(res=>{
          if(res){
            this.$.httpPost('/api/main/adm/point/insertPointCode',this.pointcode)
              .then(()=>{
                this.movePage();
              }).catch(this.$.httpErrorCommon);
          }
        })
    },

    updatePointCode(){
      this.$.popup('/adm/man/MAN934P01')
        .then(res=>{
          if(res){
            this.$.httpPost('/api/main/adm/point/updatePointCode',this.pointcode)
              .then(()=>{
                this.movePage();
              }).catch(this.$.httpErrorCommon);
          }
        })
    },
    movePage(div){
      if(div=='back') this.$router.go(-1);
      else this.$router.push('MAN934M01');
    }
  }
}
</script>